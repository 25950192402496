<template>
  <div class="container mt-4">
    <b-spinner v-if="loading"></b-spinner>
    <div v-else class="row">
      <b-col>
        <!-- header -->
        <b-row>
          <b-col class="d-flex align-items-center">
            <BackButton></BackButton>
            <h5 class="ml-2 mb-0">
              <b-link :to="`/onboarding/client/edit/${client.id}`">{{ client.display_name }}</b-link>
              -
              Client Configurations
            </h5>
          </b-col>
        </b-row>

        <!-- history -->
        <b-row class="mt-3">
          <b-col>
            <b-card :header="translations.billing_configurations.history_section_name"
                    header-class="font-weight-bold" border-variant="secondary" header-border-variant="light">
              <client-history @delete="deleteConfig" :data="history" @disabled="disabledConfig" @copy-billing-config="copyConfig"/>
            </b-card>
          </b-col>
        </b-row>

        <!-- billing configurations template -->
        <billing-template id="billing-template-component" @apply-billing-template="applyTemplate" />

        <!-- New Billing Configuration -->
        <b-row class="mt-4">
          <b-col>
            <b-card border-variant="secondary" header-border-variant="light">
              <template #header>
                <b-row>
                  <b-col class="d-flex align-items-center">
                    <b>{{ translations.billing_configurations.new_billing_model_section }}</b>
                    <div class="ml-auto d-flex align-self-end">
                      <b-button @click="saveBillingConfig"
                                id="button-submit"
                                class="float-right"
                                type="submit"
                                :disabled="saving"
                                variant="primary">
                        <b-spinner label="Loading..." variant="light" small v-if="saving"></b-spinner>
                        <span v-else>{{ translations.billing_configurations.new_billing_model_section_save_btn }}</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </template>

              <!-- Billing model & Rules -->
              <b-card :header="translations.billing_configurations.billing_model_rules_section"
                      class="border-0 mb-2" data-id="billing_model_rules_card">
                <b-row>
                  <div class="col-6">
                    <b-form-group
                      label-class="p-0"
                      :label="translations.billing_configurations.fields.billing_model"
                      :invalid-feedback="handleError('form.billing_model_id')"
                      :state="!submitted || isValid('form.billing_model_id')">
                      <b-select
                        v-model="$v.form.billing_model_id.$model"
                        :options="formatBillingOptions()"/>
                    </b-form-group>
                  </div>
                  <div class="col-6">
                    <b-form-group
                      label-class="p-0"
                      :label="translations.billing_configurations.fields.start_date"
                      :invalid-feedback="handleError('form.start_date')"
                      :state="!submitted || isValid('form.start_date')">
                      <VueCtkDateTimePicker format="YYYY-MM-DD"
                                            :min-date="minDateAvailable"
                                            label="Click to choose start date"
                                            formatted="ll"
                                            :no-button-now="true"
                                            :only-date="true"
                                            v-model="$v.form.start_date.$model"/>
                    </b-form-group>
                  </div>
                  <div class="col-6">
                    <b-form-group
                      label-class="p-0"
                      :label="translations.billing_configurations.fields.rules"
                      :invalid-feedback="handleError('form.rules_ids')"
                      :state="!submitted || isValid('form.rules_ids')">
                      <billing-rules
                        ref="billingRulesComponent"
                        :billing-model="form.billing_model_id"
                        :rules="$v.form.rules_ids.$model"
                        @rulesChanged="onRulesChanged"/>
                    </b-form-group>
                  </div>
                  <div class="col-3">
                    <b-form-group
                      label-class="p-0"
                      :label="translations.billing_configurations.fields.units"
                      :invalid-feedback="handleError('form.billing_units')"
                      :state="!submitted || isValid('form.billing_units')">
                      <multiselect
                        v-model="$v.form.billing_units.$model"
                        :options="billingUnits"
                        :multiple="true"
                        :showPointer="false"
                        :searchable="false"/>
                    </b-form-group>
                  </div>
                  <div class="col-3 form-row justify-content-around">
                    <b-form-group
                      class="mx-1"
                      :label="translations.billing_configurations.fields.with_predict"
                      :invalid-feedback="handleError('form.with_predict')"
                      :state="!submitted || isValid('form.with_predict')">
                      <toggle-button
                        :value="form.with_predict"
                        :disabled="rule40Selected"
                        @change="onChangeToggle('with_predict', ...arguments)"
                        sync/>
                    </b-form-group>
                    <b-form-group
                      class="mx-1"
                      :label="translations.billing_configurations.fields.with_cbt"
                      :invalid-feedback="handleError('form.with_cbt')"
                      :state="!submitted || isValid('form.with_cbt')">
                      <toggle-button
                        :value="form.with_cbt"
                        @change="onChangeToggle('with_cbt', ...arguments)"
                        sync/>
                    </b-form-group>
                    <b-form-group
                      class="mx-1"
                      :label="translations.billing_configurations.fields.with_ect"
                      :invalid-feedback="handleError('form.with_ect')"
                      :state="!submitted || isValid('form.with_ect')">
                      <toggle-button
                        :value="form.with_ect"
                        @change="onChangeToggle('with_ect', ...arguments)"
                        sync/>
                    </b-form-group>
                  </div>
                  <div class="col-6">
                    <b-form-group
                      label-class="p-0"
                      :label="translations.billing_configurations.fields.service_type"
                      :description="translations.billing_configurations.fields.service_type_description"
                      :invalid-feedback="handleError('form.service_type')"
                      :state="!submitted || isValid('form.service_type')">
                      <b-select
                        v-model="$v.form.service_type.$model"
                        :options="formatServiceTypeOptions()"/>
                    </b-form-group>
                  </div>
                  <div class="col-6">
                    <b-form-group
                      label-class="p-0"
                      :label="translations.billing_configurations.fields.aso_groups"
                      :description="translations.billing_configurations.fields.aso_groups_description"
                      :invalid-feedback="handleError('form.aso_groups')"
                      :state="!submitted || isValid('form.aso_groups')">
                      <multiselect
                        v-model="tenantsSelected"
                        track-by="aso_group"
                        placeholder=""
                        :options="tenants"
                        :multiple="true"
                        :showPointer="false"
                        :custom-label="formatTenantOption"
                        @input="onChangeTenantsSelected"/>
                    </b-form-group>
                  </div>
                </b-row>
              </b-card>

              <!-- Fees & Conditions -->
              <b-card :header="translations.billing_configurations.fees_conditions_section"
                      class="border-0 mb-2" data-id="fees_conditions_card">
                <b-row>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      label="Enrollment Fee"
                      description="Enrollment Fee Value ($)"
                      :invalid-feedback="handleError('form.enrollment_fee')"
                      :state="!submitted || isValid('form.enrollment_fee')"
                      label-for="input-enrollment">
                      <b-form-input
                        id="input-enrollment"
                        :value="form.enrollment_fee"
                        @input="(event) => formatNumber(event, 'enrollment_fee')"
                        type="number"
                        min="0"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      :label="translations.billing_configurations.fields.three_sessions_fee"
                      :invalid-feedback="handleError('form.three_sessions_fee')"
                      :state="!submitted || isValid('form.three_sessions_fee')"
                      label-for="input-3sessionsfee">
                      <b-form-input
                        id="input-3sessionsfee"
                        :value="form.three_sessions_fee"
                        :disabled="!standardOneThirdMilestonesActive && !is3rdSessionFieldRequired"
                        @input="(event) => formatNumber(event, 'three_sessions_fee')"
                        type="number"
                        min="0"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      :label="translations.billing_configurations.fields.nine_sessions_fee"
                      :invalid-feedback="handleError('form.nine_sessions_fee')"
                      :state="!submitted || isValid('form.nine_sessions_fee')"
                      label-for="input-9sessionsfee">
                      <b-form-input
                        id="input-9sessionsfee"
                        :value="form.nine_sessions_fee"
                        :disabled="!standardOneThirdMilestonesActive && !is9thSessionFieldRequired"
                        @input="(event) => formatNumber(event, 'nine_sessions_fee')"
                        type="number"
                        min="0"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      label="Pain score reduction fee"
                      :invalid-feedback="handleError('form.pain_score_reduction_fee')"
                      :state="!submitted || isValid('form.pain_score_reduction_fee')">
                      <b-form-input
                        :value="form.pain_score_reduction_fee"
                        @input="(event) => formatNumber(event, 'pain_score_reduction_fee')"
                        :disabled="!rule32Selected"
                        type="number"
                        min="0"/>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      label="Full Fee"
                      description="Max charged full fee per year ($)"
                      :invalid-feedback="handleError('form.full_fee')"
                      :state="!submitted || isValid('form.full_fee')"
                      label-for="input-full">
                      <b-form-input
                        id="input-full"
                        :value="form.full_fee"
                        @input="(event) => formatNumber(event, 'full_fee')"
                        type="number"
                        min="0"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      label="Session Fee"
                      description="Single Session Fee Value ($)"
                      :invalid-feedback="handleError('form.session_fee')"
                      :state="!submitted || isValid('form.session_fee')"
                      label-for="input-session">
                      <b-form-input
                        id="input-session"
                        :value="form.session_fee"
                        @input="(event) => formatNumber(event, 'session_fee')"
                        type="number"
                        min="0"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      label="Session Cap"
                      description="Max number of sessions billed per year"
                      :invalid-feedback="handleError('form.session_cap')"
                      :state="!submitted || isValid('form.session_cap')"
                      label-for="input-session-cap">
                      <b-form-input
                        id="input-session-cap"
                        :value="form.session_cap"
                        @input="(event) => formatNumber(event, 'session_cap')"
                        type="number"
                        min="0"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      label="Last X days"
                      description="Sessions performed in the last X days"
                      :invalid-feedback="handleError('form.last_x_days')"
                      :state="!submitted || isValid('form.last_x_days')"
                      label-for="input-last-days">
                      <b-form-input
                        id="input-last-days"
                        :value="form.last_x_days"
                        @input="(event) => formatNumber(event, 'last_x_days')"
                        type="number"
                        min="0"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      class="px-0"
                      :label="translations.billing_configurations.fields.pepm_fee"
                      :description="translations.billing_configurations.fields.pepm_fee_description"
                      :invalid-feedback="handleError('form.pepm_fee')"
                      :state="!submitted || isValid('form.pepm_fee')">
                      <b-form-input
                        :value="form.pepm_fee"
                        @input="(event) => formatNumber(event, 'pepm_fee')"
                        type="number"
                        min="0"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      class="px-0"
                      :label="translations.billing_configurations.fields.pepm_fee_calculation"
                      :invalid-feedback="handleError('form.pepm_fee_calculation')"
                      :state="!submitted || isValid('form.pepm_fee_calculation')">
                      <b-select
                        v-model="$v.form.pepm_fee_calculation.$model"
                        :options="pepmFeeCalculationOptions"/>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      class="px-0"
                      :label="translations.billing_configurations.fields.pepm_eligible_population"
                      :description="translations.billing_configurations.fields.pepm_eligible_population_description"
                      :invalid-feedback="handleError('form.pepm_eligible_population')"
                      :state="!submitted || isValid('form.pepm_eligible_population')">
                      <b-select
                        v-model="$v.form.pepm_eligible_population.$model"
                        :options="pepmEligiblePopulationOptions"/>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      class="px-0"
                      :label="translations.billing_configurations.fields.pepm_employee_count"
                      :description="translations.billing_configurations.fields.pepm_employee_count_description"
                      :invalid-feedback="handleError('form.pepm_employee_count')"
                      :state="!submitted || isValid('form.pepm_employee_count')">
                      <b-form-input
                        :disabled="!hasPreDeterminedEmployeeCount"
                        :value="form.pepm_employee_count"
                        @input="(event) => formatNumber(event, 'pepm_employee_count')"
                        type="number"
                        min="0"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      class="px-0"
                      :description="translations.billing_configurations.fields.not_chargeable_health_plans_description"
                      :invalid-feedback="handleError('form.not_chargeable_health_plans')"
                      :state="!submitted || isValid('form.not_chargeable_health_plans')">
                      <template #label>
                        {{ translations.billing_configurations.fields.not_chargeable_health_plans }}
                        <feather id="not_chargeable_health_plans_help_icon" class="text-info" type="info"></feather>
                        <b-tooltip target="not_chargeable_health_plans_help_icon"
                          placement="bottom">{{ translations.billing_configurations.fields.not_chargeable_health_plans_help }}</b-tooltip>
                      </template>
                      <multiselect
                        v-model="$v.form.not_chargeable_health_plans.$model"
                        :options="[]"
                        :disabled="notChargeableHealthPlansDisabled"
                        :multiple="true"
                        :taggable="true"
                        :showNoOptions="false"
                        :showNoResults="false"
                        :placeholder="translations.billing_configurations.fields.not_chargeable_health_plans_placeholder"
                        :tagPlaceholder="translations.billing_configurations.fields.not_chargeable_health_plans_tag_placeholder"
                        @tag="addNotChargeableHealthPlan" />
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      class="px-0"
                      :label="translations.billing_configurations.fields.currency"
                      :invalid-feedback="handleError('form.currency')"
                      :state="!submitted || isValid('form.currency')">
                      <b-select
                        v-model="$v.form.currency.$model"
                        :options="this.constants.CURRENCIES"/>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      :label="translations.billing_configurations.fields.move_discount_label"
                      :description="translations.billing_configurations.fields.move_discount_input_helper_text"
                      :invalid-feedback="handleError('form.move_discount')"
                      :state="!submitted || isValid('form.move_discount')"
                      label-for="move-discount">
                      <b-form-input
                        id="move-discount"
                        :value="form.move_discount"
                        :disabled="isDisabledMoveDiscount"
                        @input="(event) => formatNumber(event, 'move_discount')"
                        type="number"
                        min="0" />
                    </b-form-group>
                  </div>
                </b-row>
              </b-card>

              <!-- Price Escalator -->
              <b-card :header="translations.billing_configurations.price_escalator_section"
                      class="border-0 mb-2" data-id="fees_conditions_card">
                <b-row>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      :label="translations.billing_configurations.fields.price_escalator_percentage"
                      :description="translations.billing_configurations.fields.price_escalator_percentage_description"
                      :invalid-feedback="handleError('form.price_escalator.price_escalator_percentage')"
                      :state="!submitted || isValid('form.price_escalator.price_escalator_percentage')"
                      label-for="input-price-escalator-percentage">
                      <b-form-input
                        id="input-price-escalator-percentage"
                        v-model="$v.form.price_escalator.price_escalator_percentage.$model"
                        @input="(event) => formatNumber(event, 'price_escalator.price_escalator_percentage')"
                        type="number"
                        min="0"></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-4 px-0">
                    <b-form-group
                      label-class="p-0"
                      :label="translations.billing_configurations.fields.price_escalator_start_date"
                      :description="translations.billing_configurations.fields.price_escalator_start_date_description"
                      :invalid-feedback="handleError('form.price_escalator.price_escalator_start_date')"
                      :state="!submitted || isValid('form.price_escalator.price_escalator_start_date')">
                      <VueCtkDateTimePicker format="YYYY-MM-DD"
                                            id="price_escalator_datepicker"
                                            :disabled="noPriceEscalator()"
                                            label="Click to choose date"
                                            :min-date="minDateAvailable"
                                            formatted="ll"
                                            :no-button-now="true"
                                            :only-date="true"
                                            v-model="$v.form.price_escalator.price_escalator_start_date.$model"/>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                   <b-form-group
                      label-class="p-0"
                      :label="translations.billing_configurations.fields.price_escalator_interval"
                      :description="translations.billing_configurations.fields.price_escalator_interval_description"
                      :invalid-feedback="handleError('form.price_escalator.price_escalator_interval')"
                      :state="!submitted || isValid('form.price_escalator.price_escalator_interval')">
                      <b-select
                        :disabled="noPriceEscalator()"
                        v-model="$v.form.price_escalator.price_escalator_interval.$model"
                        :options="formatPriceEscalatorInterval()"/>
                    </b-form-group>
                  </div>
                </b-row>
              </b-card>

              <!-- Revenue Share -->
              <b-card :header="translations.billing_configurations.revenue_share_section"
                      class="border-0 mb-2" data-id="revenue_share_card">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">{{ translations.billing_configurations.revenue_share.partnership }}</th>
                      <th scope="col">{{ translations.billing_configurations.revenue_share.sharing.title }}</th>
                      <th scope="col">{{ translations.billing_configurations.revenue_share.calculation }}</th>
                      <th scope="col">{{ translations.billing_configurations.revenue_share.billing_mode }}</th>
                      <th scope="col">{{ translations.billing_configurations.revenue_share.treatment }}</th>
                      <th scope="col">{{ translations.billing_configurations.revenue_share.flat_fee_percentage }}</th>
                      <th scope="col" class="text-center actionConfigBtnWidth">
                        {{ translations.billing_configurations.statements_configurations.actions }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in form.revenue_shares" :key="index">
                      <td>{{ ((partnerships || []).find(p => p. id === item.partnership_id) || {}).name }}</td>
                      <td>
                        <b-form-radio-group v-model="item.sharing" :options="partnershipShareOptions" disabled/>
                      </td>
                      <td>{{ getTextFromDefinition(revenueShareCalculationOptions, item.calculation) }}</td>
                      <td>{{ getTextFromDefinition(revenueShareBillingModeOptions, item.billing_mode) }}</td>
                      <td>{{ getTextFromDefinition(revenueShareTreatmentOptions, item.treatment) }}</td>
                      <td>{{ item.calculation_value }}</td>
                      <td class="text-center">
                        <b-button
                          :data-id="`remove-revenue-share-${index}`"
                          @click="removeRevenueShareConfiguration(index)"
                          size="sm"
                          :disabled="saving"
                          variant="secondary"
                          class="col-2 actionConfigBtnWidth">
                          <feather type="minus"></feather>
                        </b-button>
                      </td>
                    </tr>
                    <tr v-if="form.revenue_shares.length < 1">
                      <td colspan="7" class="text-center text-muted">
                        {{ translations.billing_configurations.revenue_share.empty_information }}
                      </td>
                    </tr>
                    <tr v-else>
                      <td colspan="7" class="text-muted pb-1">
                        {{ translations.billing_configurations.revenue_share.add_another }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b-form-group
                          label-class="p-0"
                          :description="translations.billing_configurations.revenue_share.partnership_description"
                          :invalid-feedback="handleError('revenueShareForm.partnership_id')"
                          :state="!revenueShareFormSubmitted || isValid('revenueShareForm.partnership_id')">
                          <b-select
                            v-model="revenueShareForm.partnership_id"
                            :options="formatPartnershipOptions()"/>
                        </b-form-group>
                      </td>
                      <td>
                        <b-form-group
                          label-class="p-0">
                          <b-form-radio-group
                            v-model="revenueShareForm.sharing"
                            :options="partnershipShareOptions"
                            :disabled="!hasPartnership">
                          </b-form-radio-group>
                        </b-form-group>
                      </td>
                      <td class="text-center">
                        <b-form-group
                          label-class="p-0"
                          :description="translations.billing_configurations.revenue_share.calculation_description"
                          :invalid-feedback="handleError('revenueShareForm.calculation')"
                          :state="!revenueShareFormSubmitted || isValid('revenueShareForm.calculation')">
                          <b-select
                            v-model="revenueShareForm.calculation"
                            :options="revenueShareCalculationOptions"
                            :disabled="!hasRevenueShare"/>
                        </b-form-group>
                      </td>
                      <td class="text-center">
                        <b-form-group
                          label-class="p-0"
                          :description="translations.billing_configurations.revenue_share.billing_mode_description"
                          :invalid-feedback="handleError('revenueShareForm.billing_mode')"
                          :state="!revenueShareFormSubmitted || isValid('revenueShareForm.billing_mode')">
                          <b-select
                            v-model="revenueShareForm.billing_mode"
                            :options="revenueShareBillingModeOptions"
                            :disabled="!hasRevenueShare"/>
                        </b-form-group>
                      </td>
                      <td class="text-center">
                        <b-form-group
                          label-class="p-0"
                          :description="translations.billing_configurations.revenue_share.treatment_description"
                          :invalid-feedback="handleError('revenueShareForm.treatment')"
                          :state="!revenueShareFormSubmitted || isValid('revenueShareForm.treatment')">
                          <b-select
                            v-model="revenueShareForm.treatment"
                            :options="revenueShareTreatmentOptions"
                            :disabled="!hasRevenueShare"/>
                        </b-form-group>
                      </td>
                      <td class="text-center">
                        <b-form-group
                          label-class="p-0"
                          :description="revenueShareCalculationDescription"
                          :invalid-feedback="handleError('revenueShareForm.calculation_value')"
                          :state="!revenueShareFormSubmitted || isValid('revenueShareForm.calculation_value')">
                          <b-form-input
                            id="input-revenue-share-calculation-value"
                            :disabled="!hasPartnership || !revenueShareForm.calculation"
                            v-model.number="revenueShareForm.calculation_value"
                            type="number"
                            min="0"></b-form-input>
                        </b-form-group>
                      </td>
                      <td class="text-center">
                        <b-button
                          data-id="add-revenue-share"
                          @click="addRevenueShareConfiguration"
                          size="sm"
                          :disabled="saving"
                          variant="secondary"
                          class="col-2 actionConfigBtnWidth">
                          <feather type="plus"></feather>
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-card>

              <!-- Implementation Credit -->
              <b-card :header="translations.billing_configurations.implementation_credit_section"
                class="border-0 mb-2" data-id="implementation_credit_card">
                <b-row>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      :label="translations.billing_configurations.implementation_credit.payable_to"
                      :description="translations.billing_configurations.implementation_credit.payable_to_description"
                      :invalid-feedback="handleError('form.implementation_credit.payable_to')"
                      :state="isValid('form.implementation_credit.payable_to')">
                      <b-select
                            v-model="$v.form.implementation_credit.payable_to.$model"
                            :options="implementationCreditPayableToOptions"/>
                    </b-form-group>
                  </div>
                  <div class="col-4">
                    <b-form-group
                      label-class="p-0"
                      :label="translations.billing_configurations.implementation_credit.billing_mode"
                      :description="translations.billing_configurations.implementation_credit.billing_mode_description"
                      :invalid-feedback="handleError('form.implementation_credit.billing_mode')"
                      :state="isValid('form.implementation_credit.billing_mode')"
                      :disabled="!hasImplementationCreditBillingMode">
                      <b-select
                            v-model="$v.form.implementation_credit.billing_mode.$model"
                            :options="implementationCreditBillingModeOptions"/>
                    </b-form-group>
                  </div>
                </b-row>
              </b-card>

              <!-- Statements Configurations -->
              <b-card :header="translations.billing_configurations.statements_configurations_section"
                      class="border-0 mb-2" data-id="statements_configurations_card">
                <table class="table mb-0">
                  <thead>
                  <tr>
                    <th scope="col" class="text-center">
                      {{ translations.billing_configurations.statements_configurations.billing_type }}
                    </th>
                    <th scope="col" class="text-center">
                      {{ translations.billing_configurations.statements_configurations.billing_period }}
                    </th>
                    <th scope="col" class="text-center">
                      {{ translations.billing_configurations.statements_configurations.billing_provider }}
                    </th>
                    <th scope="col" class="text-center">
                      {{ translations.billing_configurations.statements_configurations.payer }}
                    </th>
                    <th scope="col" class="text-center">
                      {{ translations.billing_configurations.statements_configurations.generate_claims_batches }}
                    </th>
                    <th scope="col" class="text-center">
                      {{ translations.billing_configurations.statements_configurations.clearing_house }}
                    </th>
                    <th scope="col" class="text-center">
                      {{ translations.billing_configurations.statements_configurations.generate_837_file }}
                    </th>
                    <th scope="col" class="text-center">
                      {{ translations.billing_configurations.statements_configurations.generate_external_claims_report }}
                    </th>
                    <th scope="col" class="text-center actionConfigBtnWidth">
                      {{ translations.billing_configurations.statements_configurations.actions }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in form.statements_configurations" :key="index">
                    <td>{{ item.billing_type }}</td>
                    <td>{{ item.billing_period }}</td>
                    <td>{{ billingProviderLabelForId(item.billing_provider_id) }}</td>
                    <td>{{ payerLabelForId(item.payer_id) }}</td>
                    <td class="text-center"><b-form-checkbox :checked="item.generate_claims_batches" disabled/></td>
                    <td>{{ clearingHouseLabel(item.clearing_house) }}</td>
                    <td class="text-center"><b-form-checkbox :checked="item.generate_837_file" disabled/></td>
                    <td class="text-center"><b-form-checkbox :checked="item.generate_external_claims_report" disabled/></td>
                    <td class="text-center">
                      <b-button
                        @click="removeStatementConfiguration(index)"
                        size="sm"
                        :disabled="saving"
                        variant="secondary"
                        class="col-2 actionConfigBtnWidth">
                        <feather type="minus"></feather>
                      </b-button>
                    </td>
                  </tr>
                  <tr v-if="form.statements_configurations.length<1">
                    <td class="text-center text-muted" colspan="9">
                      {{ translations.billing_configurations.statements_configurations.empty_information }}
                      <div v-if="submitted && form.statements_configurations.length < 1" class="pr-3 m-0">
                        <div class="invalid-feedback text-right d-block m-0">
                          {{ translations.billing_configurations.statements_configurations.required_one_error }}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-else>
                    <td colspan="9" class="text-muted pb-1">
                      {{ translations.billing_configurations.statements_configurations.add_another }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b-form-group
                        class="p-0 m-0"
                        :invalid-feedback="handleError('statementConfigurationsForm.billing_type')"
                        :state="!statementConfigurationsFormSubmitted || isValid('statementConfigurationsForm.billing_type')">
                        <b-select
                          v-model="$v.statementConfigurationsForm.billing_type.$model"
                          :options="formatBillingTypeOptions()"/>
                      </b-form-group>
                    </td>
                    <td>
                      <b-form-group
                        class="p-0 m-0"
                        :invalid-feedback="handleError('statementConfigurationsForm.billing_period')"
                        :state="!statementConfigurationsFormSubmitted || isValid('statementConfigurationsForm.billing_period')">
                        <b-select
                          v-model="$v.statementConfigurationsForm.billing_period.$model"
                          :options="formatBillingPeriodOptions()"/>
                      </b-form-group>
                    </td>
                    <td>
                      <b-form-group
                        class="p-0 m-0"
                        :invalid-feedback="handleError('statementConfigurationsForm.billing_provider_id')"
                        :state="!statementConfigurationsFormSubmitted || isValid('statementConfigurationsForm.billing_provider_id')">
                        <b-select
                          v-model="$v.statementConfigurationsForm.billing_provider_id.$model"
                          :options="formatBillingProviderOptions()"/>
                      </b-form-group>
                    </td>
                    <td>
                      <b-form-group
                        class="p-0 m-0"
                        :invalid-feedback="handleError('statementConfigurationsForm.payer_id')"
                        :state="!statementConfigurationsFormSubmitted || isValid('statementConfigurationsForm.payer_id')">
                        <b-select
                          v-model="$v.statementConfigurationsForm.payer_id.$model"
                          :options="formatAvailablePayerOptions()"/>
                      </b-form-group>
                    </td>
                    <td class="text-center">
                      <b-form-group
                        class="p-0 m-0"
                        :invalid-feedback="handleError('statementConfigurationsForm.generate_claims_batches')"
                        :state="!statementConfigurationsFormSubmitted || isValid('statementConfigurationsForm.generate_claims_batches')">
                        <b-form-checkbox
                          v-model="$v.statementConfigurationsForm.generate_claims_batches.$model"
                          :disabled="generateClaimsBatchesOrExternalClaimsReportDisabled()"/>
                      </b-form-group>
                    </td>
                    <td>
                      <b-form-group
                        class="p-0 m-0"
                        :disabled="true"
                        :invalid-feedback="handleError('statementConfigurationsForm.clearing_house')"
                        :state="!statementConfigurationsFormSubmitted || isValid('statementConfigurationsForm.clearing_house')">
                        <b-select
                          v-model="$v.statementConfigurationsForm.clearing_house.$model"
                          :options="formatClearingHouseOptions()"/>
                      </b-form-group>
                    </td>
                    <td class="text-center">
                      <b-form-group
                        class="p-0 m-0"
                        :invalid-feedback="handleError('statementConfigurationsForm.generate_837_file')"
                        :state="!statementConfigurationsFormSubmitted || isValid('statementConfigurationsForm.generate_837_file')">
                        <b-form-checkbox
                          v-model="$v.statementConfigurationsForm.generate_837_file.$model"
                          :disabled="generate837FileDisabled()"/>
                      </b-form-group>
                    </td>
                    <td class="text-center">
                      <b-form-group
                        class="p-0 m-0"
                        :invalid-feedback="handleError('statementConfigurationsForm.generate_external_claims_report')"
                        :state="!statementConfigurationsFormSubmitted || isValid('statementConfigurationsForm.generate_external_claims_report')">
                        <b-form-checkbox
                          v-model="$v.statementConfigurationsForm.generate_external_claims_report.$model"
                          :disabled="generateClaimsBatchesOrExternalClaimsReportDisabled()"/>
                      </b-form-group>
                    </td>
                    <td class="text-center">
                      <b-button
                        @click="addStatementConfiguration"
                        size="sm"
                        :disabled="saving"
                        variant="secondary"
                        class="col-2 actionConfigBtnWidth">
                        <feather type="plus"></feather>
                      </b-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-card>
            </b-card>

            <!-- Client Billing Configurations -->
            <client-configurations :client-id="client.id" :billing-configs="history" class="mt-4"/>

            <!-- Payers -->
            <b-card :header="translations.payers.section_title" header-class="font-weight-bold"
                    border-variant="secondary" header-border-variant="light" class="mt-4">
              <div v-if="client.id">
                <payers ref="payersComponent" :client-id="client.id" :billing-configs="history" @data="onPayersData"/>
              </div>
            </b-card>

          </b-col>
        </b-row>
      </b-col>
    </div>
  </div>
</template>

<script>
import {
  required, minValue, maxValue, requiredIf, minLength, numeric,
} from 'vuelidate/lib/validators';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { set, cloneDeep } from 'lodash';

import errorHandler from '@/mixins/errorHandler';
import revisionRequestMixin from '@/mixins/revisionRequests';
import revisionRequestFinanceMixin from '@/mixins/revisionRequests/finance';
import BillingTemplate from '@/components/Financial/BillingTemplate/BillingTemplate.vue';
import BackButton from '@/components/BackButton.vue';
import BillingRules from '@/components/Financial/BillingRules.vue';
import ClientHistory from '@/components/Financial/ClientHistory.vue';
import ClientConfigurations from '@/components/Financial/ClientConfigurations.vue';
import Payers from '@/components/Financial/Payers/Payers.vue';
import optionsDefault from '@/components/Financial/BillingTemplate/templates/optionsDefault';
import translations from '@/translations';
import {
  revenueShareCalculationOptions,
  revenueShareBillingModeOptions,
  revenueShareTreatmentOptions,
  implementationCreditPayableToOptions,
  implementationCreditBillingModeOptions,
  pepmEligiblePopulationOptions,
  pepmFeeCalculationOptions,
} from '@/constants/finance';
import * as constants from '@/constants/finance';
import { REVISION_STATUS, ENTITY_TYPES } from '@/constants/revisionRequest';

export default {
  name: 'ClientBillingSettings',
  components: {
    ClientConfigurations,
    Payers,
    BillingTemplate,
    BackButton,
    BillingRules,
    ClientHistory,
    Multiselect,
  },
  mixins: [
    errorHandler,
    revisionRequestMixin,
    revisionRequestFinanceMixin,
  ],
  data() {
    return {
      constants,
      saving: false,
      loading: true,
      submitted: false,
      client: {},
      translations: translations.finance,
      revisionTranslations: translations.revision_requests,
      claimsBillingTypes: [ 'Claims', 'Claims (external)' ],
      form: cloneDeep(optionsDefault),
      statementConfigurationsForm: {
        billing_type: null,
        billing_period: null,
        billing_provider_id: null,
        payer_id: null,
        generate_claims_batches: null,
        generate_837_file: null,
        generate_external_claims_report: null,
        clearing_house: '',
      },
      statementConfigurationsFormSubmitted: false,
      statementConfigurationsFormValidationId: 1,
      revenueShareForm: {
        partnership_id: null,
        sharing: null,
        calculation: null,
        billing_mode: null,
        treatment: null,
        calculation_value: null,
      },
      revenueShareFormSubmitted: false,
      history: [],
      billingModelConfigurations: null,
      billingProviders: [],
      billingUnits: [],
      payers: [],
      tenants: [],
      tenantsSelected: [],
      partnerships: null,
      hasPartnership: false,
      hasRevenueShare: false,
      hasImplementationCreditBillingMode: false,
      hasPreDeterminedEmployeeCount: false,
      partnershipShareOptions: [
        { value: true, text: translations.finance.billing_configurations.revenue_share.sharing.yes },
        { value: false, text: translations.finance.billing_configurations.revenue_share.sharing.no },
      ],
      revenueShareCalculationOptions,
      revenueShareBillingModeOptions,
      revenueShareTreatmentOptions,
      implementationCreditPayableToOptions,
      implementationCreditBillingModeOptions,
      pepmEligiblePopulationOptions,
      pepmFeeCalculationOptions,
      revenueShareCalculationFields: {
        percentage_transactions: {
          label: translations.finance.billing_configurations.revenue_share.calculation_share_percentage,
          description: translations.finance.billing_configurations.revenue_share.calculation_share_percentage_description,
        },
        flat_fee_enrollment: {
          label: translations.finance.billing_configurations.revenue_share.calculation_share_flat_fee,
          description: translations.finance.billing_configurations.revenue_share.calculation_share_flat_fee_description,
        },
      },
      notChargeableHealthPlansDisabled: true,
      standardOneThirdMilestonesActive: false,
      is3rdSessionFieldRequired: false,
      is9thSessionFieldRequired: false,
      isDisabledMoveDiscount: true,
      rule32Selected: false,
      rule40Selected: false,
      rule41Selected: false,
      rule44Selected: false,
    };
  },
  validations() {
    return this.rules;
  },
  async beforeMount() {
    const clientId = Number(this.$route.params.clientId);
    this.initRevisionRequest('finance');

    const initializationPromises = [
      this.$store.dispatch('Financial/prefillBillingModels'),
      this.$store.dispatch('Financial/prefillBillingProviders'),
      this.$store.dispatch('Financial/prefillPartnerships'),
      this.$store.dispatch('Financial/prefillBillingUnits'),
      this.$store.dispatch('Core/fetchClients'),
      this.$store.dispatch('Eligibility/fetchTenants', { clientId }),
    ];
    await Promise.all(initializationPromises);

    this.billingModelConfigurations = this.$store.getters['Financial/getBillingModelConfigurations'];
    this.billingProviders = this.$store.getters['Financial/getBillingProviders'];
    this.billingUnits = this.$store.getters['Financial/getBillingUnits'].map(u => u.name || '');
    this.partnerships = this.$store.getters['Financial/getPartnerships'];
    this.tenants = this.$store.getters['Eligibility/getTenants'];
    this.client = this.$store.getters['Core/getClients'].find(c => c.id === clientId);

    this.history = await this.$store.dispatch('Financial/getBillingHistory', clientId);

    this.resetForm();
    this.loading = false;
  },
  computed: {
    minDateAvailable() {
      return moment().utc().add(1, 'day').format('YYYY-MM-DD');
    },
    rules() {
      const revenueShareRules = {
        partnership_id: { required },
        calculation: {
          requiredIf: requiredIf(elem => elem.sharing === true),
        },
        billing_mode: {
          requiredIf: requiredIf(elem => elem.sharing === true),
        },
        treatment: {
          requiredIf: requiredIf(elem => elem.sharing === true),
        },
        calculation_value: {
          requiredIf: requiredIf(elem => elem.calculation !== null),
          minValue: minValue(0.01),
        },
      };
      const statementConfigurationsRules = {
        billing_type: { required },
        billing_period: { required },
        billing_provider_id: { required },
        payer_id: {
          requiredIf: requiredIf(elem => this.claimsBillingTypes.includes(elem.billing_type)),
          'Max 1 without payer': (value, elem) => {
            if (value) {
              return true;
            }
            return !this.form.statements_configurations
              .find(existingConfig => existingConfig.validation_id !== elem.validation_id && !existingConfig.payer_id);
          },
          'No repeated payers': (value, elem) => {
            if (!value) {
              return true;
            }
            return !this.form.statements_configurations
              .find(existingConfig => existingConfig.validation_id !== elem.validation_id && existingConfig.payer_id === value);
          },
        },
        generate_claims_batches: {
          'Cannot be selected': (value, elem) => {
            if (!value) {
              return true;
            }
            return this.claimsBillingTypes.includes(elem.billing_type);
          },
        },
        generate_837_file: {
          'Cannot be selected': (value, elem) => {
            if (!value) {
              return true;
            }
            return elem.generate_claims_batches;
          },
        },
        generate_external_claims_report: {
          'Cannot be selected': (value, elem) => {
            if (!value) {
              return true;
            }
            return this.claimsBillingTypes.includes(elem.billing_type);
          },
        },
        clearing_house: {
          requiredIf: requiredIf(elem => elem.generate_claims_batches),
        },
      };
      const commonRules = {
        form: {
          billing_model_id: { required },
          start_date: { required },
          rules_ids: {
            requiredIf: requiredIf(elem => elem.billing_model_id !== this.constants.PEPM_BILLING_MODEL_ID),
          },
          billing_units: {
            'Predict billing is only applicable to sword or bloom units': value => {
              if (!this.form.with_predict) {
                return true;
              }
              return value?.includes(this.constants.UNITS.SWORD) || value?.includes(this.constants.UNITS.BLOOM);
            },
            required,
          },
          service_type: { },
          aso_groups: { },
          enrollment_fee: {
            requiredIf: requiredIf(() => this.enrollmentFeeRequired()),
            minValue: minValue(0),
          },
          three_sessions_fee: {
            requiredIf: requiredIf(
              () => this.standardOneThirdMilestonesActive || this.is3rdSessionFieldRequired,
            ),
            minValue: minValue(1),
          },
          nine_sessions_fee: {
            requiredIf: requiredIf(
              () => this.standardOneThirdMilestonesActive || this.is9thSessionFieldRequired,
            ),
            minValue: minValue(1),
          },
          pain_score_reduction_fee: {
            minValue: minValue(0),
            requiredIf: requiredIf(() => this.rule32Selected),
          },
          session_fee: { minValue: minValue(0) },
          full_fee: {
            'Full fee amount is not correct according to the rest of the configurations': value => {
              if (!value) {
                return true;
              }

              if (this.hasStandardOneThirdFieldsRequired()) {
                return true;
              }

              if (!(this.form.enrollment_fee && this.form.session_fee && value && this.form.session_cap)) {
                return true;
              }

              const fullFee = parseFloat(value).toFixed(2);
              return fullFee === parseFloat(this.form.enrollment_fee + (this.form.session_fee * this.form.session_cap)).toFixed(2);
            },
            'Full fee must equal the result of the Enrollment Fee + 3 sessions fee + 9 sessions fee': value => {
              if (!value) {
                return true;
              }

              if (!this.hasStandardOneThirdFieldsRequired() || this.rule32Selected) {
                return true;
              }

              if (this.standardOneThirdMilestonesActive) {
                if (!(this.form.enrollment_fee && this.form.three_sessions_fee && this.form.nine_sessions_fee && value)) {
                  return true;
                }
              } else if (this.is3rdSessionFieldRequired) {
                if (!(this.form.enrollment_fee && this.form.three_sessions_fee && value)) {
                  return true;
                }
              } else if (this.is9thSessionFieldRequired) {
                if (!(this.form.enrollment_fee && this.form.nine_sessions_fee && value)) {
                  return true;
                }
              }

              const fullFee = parseFloat(value).toFixed(2);
              return fullFee === parseFloat(this.form.enrollment_fee + this.form.three_sessions_fee + this.form.nine_sessions_fee).toFixed(2);
            },
            'Full fee must equal the result of the Enrollment Fee + 3 sessions fee + 9 sessions fee + Pain score reduction fee': value => {
              if (!value) {
                return true;
              }
              if (!this.rule32Selected) {
                return true;
              }
              if (!(this.form.enrollment_fee
                  && this.form.three_sessions_fee
                  && this.form.nine_sessions_fee
                  && this.form.pain_score_reduction_fee
                  && value)) {
                return true;
              }

              const fullFee = parseFloat(value).toFixed(2);
              return fullFee === parseFloat(this.form.enrollment_fee
                  + this.form.three_sessions_fee
                  + this.form.nine_sessions_fee
                  + this.form.pain_score_reduction_fee).toFixed(2);
            },
            requiredIf: requiredIf(elem => elem.billing_model_id !== this.constants.PEPM_BILLING_MODEL_ID),
            minValue: minValue(1),
          },
          session_cap: { minValue: minValue(0) },
          move_discount: {
            requiredIf: requiredIf(elem => elem.billing_model_id === this.constants.MOVE_BILLING_MODEL_ID),
            minValue: minValue(0),
            maxValue: maxValue(100),
          },
          last_x_days: { minValue: minValue(0) },
          pepm_fee: {},
          pepm_fee_calculation: {
            requiredIf: requiredIf(elem => elem.pepm_fee > 0),
          },
          pepm_eligible_population: {
            'PEPM requires a Direct Invoice statement': value => {
              if (!value) {
                return true;
              }
              return this.form.statements_configurations.some(s => s.billing_type === 'Direct Invoice');
            },
          },
          pepm_employee_count: {
            requiredIf: requiredIf(elem => elem.pepm_eligible_population === this.constants.PRE_DETERMINED_EMPLOYEE_COUNT),
            numeric,
            minValue: minValue(1),
          },
          not_chargeable_health_plans: {},
          currency: { required },
          price_escalator: {
            price_escalator_percentage: {},
            price_escalator_start_date: {},
            price_escalator_interval: {},
          },
          statements_configurations: {
            required,
            minLength: minLength(1),
            $each: statementConfigurationsRules,
          },
          revenue_shares: {
            $each: revenueShareRules,
          },
          implementation_credit: {
            payable_to: {},
            billing_mode: {
              requiredIf: requiredIf(() => this.form.implementation_credit.payable_to !== null),
              'Please select a billing mode': value => {
                if (value) {
                  return true;
                }
                return this.form.implementation_credit.payable_to === null;
              },
            },
          },
        },
        statementConfigurationsForm: statementConfigurationsRules,
        revenueShareForm: revenueShareRules,
      };
      if (!this.noPriceEscalator()) {
        commonRules.form.price_escalator.price_escalator_interval = { required };
        commonRules.form.price_escalator.price_escalator_percentage = { required, minValue: minValue(0.1), maxValue: maxValue(100) };
        commonRules.form.price_escalator.price_escalator_start_date = { required };
      }
      return commonRules;
    },
    revenueShareCalculationDescription() {
      if (!this.revenueShareCalculationFields[this.revenueShareForm.calculation]) {
        return '';
      }
      return this.revenueShareCalculationFields[this.revenueShareForm.calculation].description;
    },
  },
  watch: {
    'statementConfigurationsForm.billing_type': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.statementConfigurationsForm.generate_claims_batches = false;
          this.statementConfigurationsForm.generate_external_claims_report = false;
          this.statementConfigurationsForm.clearing_house = '';
        }
      },
    },
    'statementConfigurationsForm.generate_claims_batches': {
      handler(newVal) {
        if (!newVal) {
          this.statementConfigurationsForm.generate_837_file = false;
          this.statementConfigurationsForm.clearing_house = '';
        } else if (this.statementConfigurationsForm.billing_type !== 'Direct Invoice') {
          this.statementConfigurationsForm.clearing_house = this.constants.DEFAULT_CLEARING_HOUSE;
        }
      },
    },
    'revenueShareForm.partnership_id': {
      handler() {
        this.hasPartnership = this.revenueShareForm.partnership_id > 0;
        this.revenueShareForm.sharing = false;
        if (!this.hasPartnership) {
          this.revenueShareForm.sharing = null;
        }
      },
    },
    'revenueShareForm.sharing': {
      handler() {
        this.hasRevenueShare = this.revenueShareForm.sharing;
        if (!this.hasRevenueShare) {
          this.revenueShareForm.calculation = null;
          this.revenueShareForm.billing_mode = null;
          this.revenueShareForm.treatment = null;
        }
      },
    },
    'form.implementation_credit.payable_to': {
      handler() {
        this.hasImplementationCreditBillingMode = this.form.implementation_credit.payable_to;
        if (!this.hasImplementationCreditBillingMode) {
          this.form.implementation_credit.billing_mode = null;
        }
      },
    },
    'form.pepm_eligible_population': {
      handler() {
        this.hasPreDeterminedEmployeeCount = this.form.pepm_eligible_population === this.constants.PRE_DETERMINED_EMPLOYEE_COUNT;
        if (!this.hasPreDeterminedEmployeeCount) {
          this.form.pepm_employee_count = null;
        }
      },
    },
    'form.billing_model_id': {
      handler() {
        this.standardOneThirdMilestonesActive = this.constants.DEFAULT_3RD_9TH_BILLING_MODELS.includes(this.form.billing_model_id);
        if (!this.standardOneThirdMilestonesActive) {
          if (!this.is3rdSessionFieldRequired) this.form.three_sessions_fee = null;
          if (!this.is9thSessionFieldRequired) this.form.nine_sessions_fee = null;
        }

        this.isDisabledMoveDiscount = this.form.billing_model_id !== this.constants.MOVE_BILLING_MODEL_ID;
        if (this.isDisabledMoveDiscount) {
          this.move_discount = null;
        }
      },
    },
  },
  methods: {
    hasStandardOneThirdFieldsRequired() {
      return this.standardOneThirdMilestonesActive || this.is3rdSessionFieldRequired || this.is9thSessionFieldRequired;
    },
    enrollmentFeeRequired() {
      return this.hasStandardOneThirdFieldsRequired() || this.rule41Selected || this.rule44Selected;
    },
    async saveBillingConfig() {
      this.submitted = true;
      if (this.$v.form.$invalid) {
        return;
      }
      if (!await this.confirmCreation()) {
        this.submitted = false;
        return;
      }

      this.saving = true;
      const body = {};
      Object.keys(this.form).forEach(key => { body[key] = this.form[key]; });
      for (let i = 0; i < body.statements_configurations.length; i++) {
        delete body.statements_configurations[i].validation_id;
      }
      const clientID = Number(this.$route.params.clientId);

      try {
        const newBillingConfig = await this.$store.dispatch('Financial/saveBillingConfig', { clientID, body });

        await this.createRevisionRequest(newBillingConfig);

        this.history = await this.$store.dispatch('Financial/getBillingHistory', clientID);
        this.submitted = false;
        this.resetForm();
      } finally {
        this.saving = false;
      }
    },
    async createRevisionRequest(newBillingConfig) {
      const revisionData = this.makeRevisionData(
        newBillingConfig.id,
        ENTITY_TYPES.financeBillingModel,
        { status: this.constants.BILLING_CONFIG_STATUS_NEW },
        null,
      );

      // force create revision request with status pending_approval
      revisionData.status = REVISION_STATUS.pending;

      try {
        return await this.saveRevision(revisionData);
      } catch (err) {
        this.$noty.error(err?.response?.data?.error?.detail || this.revisionTranslations.errors.save);
        return false;
      }
    },
    noPriceEscalator() {
      return this.form.price_escalator.price_escalator_percentage === null
        || this.form.price_escalator.price_escalator_percentage === ''
        || this.form.price_escalator.price_escalator_percentage <= 0;
    },
    formatBillingOptions() {
      if (this.billingModelConfigurations) {
        return this.billingModelConfigurations.map(v => ({ value: v.id, text: v.name }));
      }
      return [];
    },
    formatServiceTypeOptions() {
      return [
        { text: '', value: null },
        { text: 'PT Provider', value: 'pt_provider' },
        { text: 'Wellness', value: 'wellness' },
      ];
    },
    formatBillingProviderOptions() {
      return (this.billingProviders || [])
        .map(v => ({ value: v.id, text: v.name }));
    },
    formatTenantOption(option) {
      return option.aso_group_name;
    },
    onChangeTenantsSelected(opts) {
      this.form.aso_groups = opts?.map(t => t.aso_group);
    },
    formatBillingTypeOptions() {
      return [ 'Direct Invoice', 'Claims', 'Claims (external)' ];
    },
    formatPriceEscalatorInterval() {
      return [
        { value: null, text: 'Please select one option' },
        { value: '12_months', text: '12 Months' },
        { value: '24_months', text: '24 Months' },
        { value: '36_months', text: '36 Months' },
      ];
    },
    formatBillingPeriodOptions() {
      return [ 'Monthly', 'Daily', 'Weekly', 'Bi-weekly' ];
    },
    formatPartnershipOptions() {
      return [
        { value: null, text: '--- None ---' },
        ...(this.partnerships || []).map(v => ({ value: v.id, text: v.name })),
      ];
    },
    onRulesChanged(event) {
      this.form.rules_ids = event.rules;

      this.is3rdSessionFieldRequired = false;
      this.is9thSessionFieldRequired = false;
      this.rule32Selected = false;
      this.rule40Selected = false;
      this.notChargeableHealthPlansDisabled = true;
      this.rule41Selected = false;
      this.rule44Selected = false;

      for (let i = 0; i < this.form.rules_ids.length; i++) {
        if ([ 17, 18 ].includes(this.form.rules_ids[i])) {
          this.notChargeableHealthPlansDisabled = false;
        }

        if (this.form.rules_ids[i] === 32) {
          this.rule32Selected = true;
        }

        if (this.constants.RULES_3RD_SESSIONS_REQUIRED.includes(this.form.rules_ids[i])) {
          this.is3rdSessionFieldRequired = true;
        }

        if (this.constants.RULES_9TH_SESSIONS_REQUIRED.includes(this.form.rules_ids[i])) {
          this.is9thSessionFieldRequired = true;
        }
        if (this.form.rules_ids[i] === this.constants.RULE_40_PREDICTED) {
          this.rule40Selected = true;
        }
        if (this.form.rules_ids[i] === this.constants.RULE_41_1ST_MILESTONE) {
          this.rule41Selected = true;
        }
        if (this.form.rules_ids[i] === this.constants.RULE_44_1ST_SESSION_Y2) {
          this.rule44Selected = true;
        }
      }

      if (!this.is3rdSessionFieldRequired) this.form.three_sessions_fee = null;
      if (!this.is9thSessionFieldRequired) this.form.nine_sessions_fee = null;

      if (this.notChargeableHealthPlansDisabled) {
        this.form.not_chargeable_health_plans = [];
      }

      if (!this.rule32Selected) {
        this.form.pain_score_reduction_fee = null;
      }

      if (this.rule40Selected) {
        this.onChangeToggle('with_predict', { value: true });
      }
    },
    formatNumber(value, path) {
      if (value === '') {
        set(this.form, path, null);
      } else {
        set(this.form, path, Number(value));
      }
    },
    resetForm() {
      this.form = cloneDeep(optionsDefault);
      this.resetStatementConfigurationsForm();
      this.resetRevenueShareForm();
      this.resetASOGroupsForm();
    },
    deleteConfig(id) {
      this.history = this.history.filter(conf => conf.id !== id);
    },
    async disabledConfig(clientID) {
      this.history = await this.$store.dispatch('Financial/getBillingHistory', clientID);
    },
    onPayersData(payers) {
      this.payers = payers;
    },
    formatAvailablePayerOptions() {
      return this.payers
        .filter(v => !this.form.statements_configurations
          .find(existingConfig => v.id === existingConfig.payer_id))
        .map(v => ({ value: v.id, text: this.payerLabel(v) }));
    },
    formatClearingHouseOptions() {
      return [
        { value: '', text: '' },
        { value: 'availity', text: 'Availity' },
        { value: 'waystar', text: 'Waystar' },
      ];
    },
    resetStatementConfigurationsForm() {
      [ this.statementConfigurationsForm.billing_type ] = this.formatBillingTypeOptions();
      [ this.statementConfigurationsForm.billing_period ] = this.formatBillingPeriodOptions();
      this.statementConfigurationsForm.billing_provider_id = this.constants.DEFAULT_PROVIDER_ID;
      this.statementConfigurationsForm.payer_id = null;
      this.statementConfigurationsForm.generate_claims_batches = false;
      this.statementConfigurationsForm.generate_837_file = false;
      this.statementConfigurationsForm.generate_external_claims_report = false;
      this.statementConfigurationsForm.clearing_house = this.formatClearingHouseOptions()[0].value;
    },
    resetASOGroupsForm() {
      this.form.aso_groups = [];
      this.tenantsSelected = [];
    },
    resetRevenueShareForm() {
      this.revenueShareForm.partnership_id = null;
      this.revenueShareForm.sharing = null;
      this.revenueShareForm.calculation = null;
      this.revenueShareForm.calculation_value = null;
      this.revenueShareForm.billing_mode = null;
      this.revenueShareForm.treatment = null;
    },
    addStatementConfiguration() {
      this.statementConfigurationsFormSubmitted = true;
      if (this.$v.statementConfigurationsForm.$invalid) {
        return;
      }
      this.statementConfigurationsFormSubmitted = false;
      const newConfig = JSON.parse(JSON.stringify(this.statementConfigurationsForm));
      newConfig.validation_id = this.statementConfigurationsFormValidationId;
      this.statementConfigurationsFormValidationId++;
      this.form.statements_configurations.push(newConfig);
      this.resetStatementConfigurationsForm();
    },
    addRevenueShareConfiguration() {
      this.revenueShareFormSubmitted = true;
      if (this.$v.revenueShareForm.$invalid) {
        return;
      }
      this.revenueShareFormSubmitted = false;
      const newConfig = JSON.parse(JSON.stringify(this.revenueShareForm));
      this.form.revenue_shares.push(newConfig);
      this.resetRevenueShareForm();
    },
    removeStatementConfiguration(index) {
      this.form.statements_configurations.splice(index, 1);
    },
    removeRevenueShareConfiguration(index) {
      this.form.revenue_shares.splice(index, 1);
    },
    payerLabel(payer) {
      return `${payer.payer_external_id} - ${payer.payer_name}`;
    },
    payerLabelForId(payerId) {
      if (!payerId) {
        return '';
      }
      const payer = this.payers.find(v => v.id === payerId);
      if (payer) {
        return this.payerLabel(payer);
      }
      return `ERROR_NO_LABEL_${payerId}`;
    },
    clearingHouseLabel(clearingHouse) {
      return (this.formatClearingHouseOptions().find(ch => ch.value === clearingHouse) || {}).text;
    },
    billingProviderLabelForId(billingProviderId) {
      const billingProvider = this.billingProviders.find(v => v.id === billingProviderId);
      if (billingProvider) {
        return billingProvider.name;
      }
      return `ERROR_NO_LABEL_${billingProviderId}`;
    },
    generateClaimsBatchesOrExternalClaimsReportDisabled() {
      return !this.claimsBillingTypes.includes(this.statementConfigurationsForm.billing_type);
    },
    generate837FileDisabled() {
      return !this.statementConfigurationsForm.generate_claims_batches || this.generateClaimsBatchesOrExternalClaimsReportDisabled();
    },
    getTextFromDefinition(definitions, value) {
      return ((definitions || []).find(i => i.value === value) || {}).text;
    },
    async confirmCreation() {
      let result = true;

      const activeConfigsBeforeNewConfig = Object.values(this.getActiveConfigsBeforeNewConfig());

      const missingUnits = this.getUnitsWillMissConfiguration(activeConfigsBeforeNewConfig);
      if (missingUnits.length > 0) {
        result = await this.confirmCreationMissingUnits(missingUnits);
      }
      if (!result) {
        return false;
      }


      const unitsWithCompetingConfig = this.calculateUnitsWithCompetingConfig(activeConfigsBeforeNewConfig);
      if (unitsWithCompetingConfig.length > 0) {
        result = await this.confirmCreationUnitsWithCompetingConfig(unitsWithCompetingConfig);
      }

      return result;
    },
    getActiveConfigsBeforeNewConfigKey(unit, serviceType) {
      return `${unit}_${serviceType}`;
    },
    /**
     * Calculates what wil be the active billing configs before the new configuration in the form is applied.
     * @returns {Object} { <unit name>_<service type>: history }
     */
    getActiveConfigsBeforeNewConfig() {
      const active = {};
      const activeHistoryId = {};

      // start with active configs
      this.history
        .filter(h => h.status === 'active')
        .forEach(h => {
          h.billing_units.forEach(u => {
            const key = this.getActiveConfigsBeforeNewConfigKey(u.name, h.service_type);
            active[key] = h;
            activeHistoryId[key] = h.id;
          });
        });

      // get changes that will happen before or at the same day as the new config
      const futureChanges = this.history
        .filter(h => h.status === 'new') // new configs
        .filter(h => moment.utc(h.start_date) <= moment.utc(this.form.start_date)); // that start before or at the same day as the new one
      futureChanges.sort((a, b) => moment.utc(a.start_date) - moment.utc(b.start_date));

      // replay the effect of each change
      for (let i = 0; i < futureChanges.length; i++) {
        futureChanges[i].billing_units.forEach(u => {
          const key = this.getActiveConfigsBeforeNewConfigKey(u.name, futureChanges[i].service_type);
          const hasActive = active[key];
          if (hasActive) {
            // delete all with this id
            const configIdThatEnabledIt = activeHistoryId[key];
            Object.keys(activeHistoryId).forEach(activeKey => {
              if (activeHistoryId[activeKey] === configIdThatEnabledIt) {
                delete active[activeKey];
                delete activeHistoryId[activeKey];
              }
            });
          }
          active[key] = futureChanges[i];
          activeHistoryId[key] = futureChanges[i].id;
        });
      }

      return active;
    },
    /**
     * Calculate units that will lose configuration without service type because of this new config.
     * @param {Array} activeConfigsBeforeNewConfig
     * @returns {Array.<String>}
     */
    getUnitsWillMissConfiguration(activeConfigsBeforeNewConfig) {
      const active = {};
      const activeHistoryId = {};
      const activeUnitsDayBefore = {};
      // populate vars
      activeConfigsBeforeNewConfig.forEach(activeConfigBeforeNewConfig => {
        activeConfigBeforeNewConfig.billing_units.forEach(u => {
          if (!activeConfigBeforeNewConfig.service_type) {
            activeUnitsDayBefore[u.name] = true;
          }

          const key = this.getActiveConfigsBeforeNewConfigKey(u.name, activeConfigBeforeNewConfig.service_type);
          active[key] = activeConfigBeforeNewConfig;
          activeHistoryId[key] = activeConfigBeforeNewConfig.id;
        });
      });

      // replay this config
      this.form.billing_units.forEach(u => {
        const key = this.getActiveConfigsBeforeNewConfigKey(u, this.form.service_type);
        const hasActive = active[key];
        if (hasActive) {
          // delete all with this id
          const configIdThatEnabledIt = activeHistoryId[key];
          Object.keys(activeHistoryId).forEach(activeKey => {
            if (activeHistoryId[activeKey] === configIdThatEnabledIt) {
              delete active[activeKey];
              delete activeHistoryId[activeKey];
            }
          });
        }
        active[key] = this.form;
      });

      // active units after replay
      const activeUnits = {};
      Object.keys(active).forEach(key => {
        if (!active[key].service_type) {
          active[key].billing_units.forEach(u => {
            activeUnits[u.name ? u.name : u] = true;
          });
        }
      });

      // list what was configured that is not now
      const result = [];
      Object.keys(activeUnitsDayBefore).forEach(uName => {
        if (activeUnitsDayBefore[uName] && !activeUnits[uName]) {
          result.push(uName);
        }
      });
      return result;
    },
    async confirmCreationMissingUnits(missingUnits) {
      const h = this.$createElement;
      const messageVNode = h('div', [
        h('p', [
          h('strong', missingUnits.join(', ')),
          ' ',
          this.translations.billing_configurations.confirm_creation_popup_missing_units.sentence1Middle,
          ' ',
          h('strong', this.form.start_date),
          '.',
        ]),
        h('p', [
          this.translations.billing_configurations.confirm_creation_popup_missing_units.sentence2Begin,
          ' (',
          this.form.start_date,
          ') ',
          this.translations.billing_configurations.confirm_creation_popup_missing_units.sentence2End,
        ]),
      ]);

      return this.$bvModal.msgBoxConfirm([ messageVNode ], {
        title: this.translations.billing_configurations.confirm_creation_popup_missing_units.title,
        okTitle: this.translations.billing_configurations.confirm_creation_popup_missing_units.ok,
        size: 'lg',
      });
    },
    /**
     * Calculate units that, at start_date of the new config, will have configs with and without service type.
     * @param {Array} activeConfigsBeforeNewConfig
     * @returns {Array.<String>}
     */
    calculateUnitsWithCompetingConfig(activeConfigsBeforeNewConfig) {
      const result = [];
      activeConfigsBeforeNewConfig.forEach(activeConfigBeforeNewConfig => {
        if (activeConfigBeforeNewConfig.service_type !== this.form.service_type
          && (activeConfigBeforeNewConfig.service_type === null || this.form.service_type === null)) {
          activeConfigBeforeNewConfig.billing_units.forEach(u => {
            if (this.form.billing_units.includes(u.name) && !result.includes(u.name)) {
              result.push(u.name);
            }
          });
        }
      });
      return result;
    },
    async confirmCreationUnitsWithCompetingConfig(unitsWithCompetingConfig) {
      const h = this.$createElement;
      const messageVNode = h('div', [
        h('p', this.translations.billing_configurations.confirm_creation_popup_units_competing_configs.sentence1),
        h('p', [
          this.translations.billing_configurations.confirm_creation_popup_units_competing_configs.sentence2Begin,
          ' (',
          h('strong', unitsWithCompetingConfig.join(', ')),
          ') ',
          this.translations.billing_configurations.confirm_creation_popup_units_competing_configs.sentence2End,
        ]),
      ]);

      return this.$bvModal.msgBoxConfirm([ messageVNode ], {
        title: this.translations.billing_configurations.confirm_creation_popup_units_competing_configs.title,
        okTitle: this.translations.billing_configurations.confirm_creation_popup_units_competing_configs.ok,
        size: 'lg',
      });
    },
    addNotChargeableHealthPlan(option) {
      if (typeof option !== 'string') {
        return;
      }
      option = option.trim();
      if (option === '') {
        return;
      }

      if (!this.form.not_chargeable_health_plans.includes(option)) {
        this.form.not_chargeable_health_plans.push(option);
      }
    },
    onChangeToggle(toggle, value) {
      this.form[toggle] = value?.value;
    },
    async applyTemplate(template) {
      this.resetForm();

      if (template.payers) {
        await this.createPayersIfNotExists(template.payers);
      }

      // statement configs
      const payersExternalIDs = this.payers.map(p => p.payer_external_id);
      const validStatementConfigs = [];
      template.statements_configurations.forEach(cfg => {
        if (!cfg.payer_external_id) {
          validStatementConfigs.push(cfg);
          return;
        }
        if (payersExternalIDs.includes(cfg.payer_external_id)) {
          cfg.payer_id = this.payers.find(p => p.payer_external_id === cfg.payer_external_id).id;
          validStatementConfigs.push(cfg);
        }
      });
      if (template.statements_configurations.length !== validStatementConfigs.length) {
        this.$noty.error(this.translations.billing_configurations.template_mismatch_statement_configs);
      }
      template.statements_configurations = validStatementConfigs;

      // billing model and rules
      this.form.billing_model_id = template.billing_model_id;
      await this.$refs.billingRulesComponent.getBillingModelConfigurations();
      const rulesToSelect = [];
      this.$refs.billingRulesComponent.availableRules.forEach(rule => {
        if (template.rules_ids.includes(rule.id)) {
          rulesToSelect.push(rule.id);
        }
      });
      this.onRulesChanged({ rules: rulesToSelect });
      if (rulesToSelect.length !== template.rules_ids.length) {
        this.$noty.error(this.translations.billing_configurations.template_mismatch_rules);
      }
      delete template.billing_model_id;
      delete template.rules_ids;

      // aso groups
      if (template.aso_groups) {
        const selectedTenants = [];
        template.aso_groups.forEach(asoGroup => {
          const tenant = this.tenants.find(t => t.aso_group === asoGroup);
          if (tenant) {
            selectedTenants.push(tenant);
          }
        });
        this.tenantsSelected = selectedTenants;
        this.onChangeTenantsSelected(selectedTenants);
        if (selectedTenants.length !== template.aso_groups.length) {
          this.$noty.error(this.translations.billing_configurations.template_mismatch_aso_groups);
        }
        delete template.aso_groups;
      }

      // revenue shares
      if (template.revenue_shares) {
        const validRevenueShares = [];
        template.revenue_shares.forEach(revenueShare => {
          if (revenueShare.partnership_id) {
            validRevenueShares.push(revenueShare);
          }
        });
        if (validRevenueShares.length !== template.revenue_shares.length) {
          this.$noty.error(this.translations.billing_configurations.template_mismatch_revenue_shares);
        }
        template.revenue_shares = validRevenueShares;
      }

      // check escalator date
      if (template.price_escalator?.price_escalator_start_date) {
        const startDate = moment(template.price_escalator.price_escalator_start_date).utc();
        if (startDate.isBefore(moment().utc())) {
          template.price_escalator.price_escalator_start_date = null;
          this.$noty.error(this.translations.billing_configurations.template_past_price_escalator_start);
        }
      }

      // apply other properties
      this.form = {
        ...this.form,
        ...template,
      };
    },
    async createPayersIfNotExists(payers) {
      await Promise.all(payers.map(async payer => {
        const payerExists = this.payers.find(p => p.payer_external_id === payer.payer_external_id);
        if (payerExists) {
          return;
        }

        await this.$refs.payersComponent.addPayer(payer);
      }));
    },
    async copyConfig(config) {
      if (!config) {
        console.error('empty billing config');
        return;
      }

      // form vars, in order they appear in the screen
      const template = {
        billing_model_id: config.billing_model?.id,
        // start date not copied
        with_predict: config.with_predict,
        with_cbt: config.with_cbt,
        with_ect: config.with_ect,
        service_type: this.nullIfEmpty(config.service_type),
        aso_groups: config.aso_groups,
        enrollment_fee: this.nullIfEmpty(config.enrollment_fee),
        three_sessions_fee: this.nullIfEmpty(config.three_sessions_fee),
        nine_sessions_fee: this.nullIfEmpty(config.nine_sessions_fee),
        pain_score_reduction_fee: this.nullIfEmpty(config.pain_score_reduction_fee),
        full_fee: this.nullIfEmpty(config.full_fee),
        session_fee: this.nullIfEmpty(config.session_fee),
        session_cap: this.nullIfEmpty(config.session_cap),
        last_x_days: this.nullIfEmpty(config.last_x_days),
        pepm_fee: this.nullIfEmpty(config.pepm_fee),
        pepm_fee_calculation: this.nullIfEmpty(config.pepm_fee_calculation),
        pepm_eligible_population: this.nullIfEmpty(config.pepm_eligible_population),
        pepm_employee_count: this.nullIfEmpty(config.pepm_employee_count),
        not_chargeable_health_plans: config.not_chargeable_health_plans,
        currency: this.nullIfEmpty(config.currency),
        move_discount: this.nullIfEmpty(config.move_discount),
        price_escalator: {
          price_escalator_start_date: this.nullIfEmpty(config.price_escalator?.price_escalator_start_date),
          price_escalator_interval: this.nullIfEmpty(config.price_escalator?.price_escalator_interval),
          price_escalator_percentage: this.nullIfEmpty(config.price_escalator?.price_escalator_percentage),
        },
        implementation_credit: {
          payable_to: this.nullIfEmpty(config.implementation_credit?.payable_to),
          billing_mode: this.nullIfEmpty(config.implementation_credit?.billing_mode),
        },
      };
      if (template.price_escalator?.price_escalator_start_date) {
        template.price_escalator.price_escalator_start_date = moment(template.price_escalator.price_escalator_start_date)
          .utc().format('YYYY-MM-DD');
      }

      const ruleIds = [];
      config.rules.forEach(rule => {
        ruleIds.push(rule.id);
      });
      template.rules_ids = ruleIds;

      const billingUnits = [];
      config.billing_units.forEach(unit => {
        billingUnits.push(unit.name);
      });
      template.billing_units = billingUnits;

      const statementsConfigurations = [];
      config.statements_configurations.forEach(cfg => {
        const statementCfg = {
          billing_type: cfg.billing_type,
          billing_period: cfg.billing_period,
          payer_external_id: cfg.payer?.payer_external_id,
          billing_provider_id: cfg.billing_provider?.id,
          generate_claims_batches: cfg.generate_claims_batches,
          generate_837_file: cfg.generate_837_file,
          generate_external_claims_report: cfg.generate_external_claims_report,
          clearing_house: cfg.clearing_house,
        };
        statementsConfigurations.push(statementCfg);
      });
      template.statements_configurations = statementsConfigurations;

      const revenueShares = [];
      config.revenue_shares.forEach(revenueShare => {
        const partnership = this.partnerships?.find(p => p.name === revenueShare.partnership_name);
        const revenueShareCfg = {
          partnership_id: partnership?.id,
          sharing: revenueShare.sharing,
          calculation: revenueShare.calculation,
          calculation_value: revenueShare.calculation_value,
          billing_mode: revenueShare.billing_mode,
          treatment: revenueShare.treatment,
        };
        revenueShares.push(revenueShareCfg);
      });
      template.revenue_shares = revenueShares;

      await this.applyTemplate(template);
    },
    nullIfEmpty(v) {
      return v || null;
    },
  },
};
</script>

<style scoped lang="scss">
.actionConfigBtnWidth {
  min-width: 60px;
  max-width: 60px;
}

</style>
